<template>
  <el-table max-height="50vh" :data="services">
    <el-table-column
      v-slot="{ row }"
      prop="name"
      :label="$t('common.headers.name.title')"
      :min-width="250"
      truncate
    >
      <el-button
        link
        @click="
          $router.push({
            name: 'services-edit',
            params: {
              id: row.id
            }
          })
        "
      >
        {{ row.name }}
      </el-button>
    </el-table-column>

    <el-table-column
      :label="$t('pages.staff.form.reservations.headers.service_category')"
      :min-width="200"
      truncate
      :formatter="(service) => service.product_group?.name ?? '-'"
    />

    <el-table-column
      prop="description"
      :label="$t('common.headers.description.title')"
      :formatter="(service) => service.description || '-'"
      :min-width="250"
      truncate
    />
  </el-table>
</template>

<script>
export default {
  name: 'StaffServicesTable',
  props: {
    serviceEntities: {
      type: Array,
      required: true
    },
    resources: {
      type: Object,
      required: true
    }
  },
  computed: {
    services() {
      const groups = this.resources.productGroups || []

      return this.serviceEntities.map((service) => {
        const group =
          groups.find((group) => {
            return group.product_group_id === service.product_group
          }) ?? null

        return {
          ...service,
          product_group: group
        }
      })
    }
  }
}
</script>
