<template>
  <group-items-select
    v-model="selectedItems"
    resource="products"
    show-search-filter
    :resource-query="{
      deleted: false,
      limit: 100,
      is_reservations_service: true
    }"
    :normalise-keys="normalizeKeys"
    full-selected-object
    :result-filter="sortServicesAlphabetically"
  />
</template>

<script>
import GroupItemsSelect from '@/components/group-items-select'

/**
 * @TODO Copy the resource query from Zarina's pull request for
 * services listing table
 */

export default {
  name: 'StaffServicesGroupItemsSelect',
  components: {
    GroupItemsSelect
  },
  props: {
    modelValue: {
      type: [Array, null],
      default: () => []
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      shownItems: [],
      loading: false
    }
  },
  computed: {
    normalizeKeys() {
      return (product) => ({
        ...product,
        title_main: this.$formatProduct(product)
      })
    },
    selectedItems: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    sortServicesAlphabetically(services) {
      return services.slice().sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    },
    fillShownItems(items = []) {
      this.shownItems = [...items]
    },
    fetchInitialValue() {
      this.setSelectedItems()
    },
    setSelectedItems() {
      this.fillShownItems(this.modelValue)
      this.selectedItems = this.modelValue
    }
  }
}
</script>
