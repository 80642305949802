import compare from 'just-compare'
import { permissionsConfigStaff } from '@/constants/permissions-constants'
import { flatPermissions } from '@/components/permissions-view/helpers'

//full default scopes
export const defaultScopes = flatPermissions(permissionsConfigStaff())

export const base = 'staff:staff'

export function getPresetPermissions(vm = { $t: (f) => f }) {
  return {
    allPermissions: {
      scopes: ['staff:staff'],
      title: vm.$t(
        'pages.staff.form.scopes.all_permissions.preselect.allPermissions'
      )
    },
    cashierFull: {
      scopes: [
        'staff:staff:analytics',
        'staff:staff:balance',
        'staff:staff:balances',
        'staff:staff:carts:delete',
        'staff:staff:carts:put_back',
        'staff:staff:carts',
        'staff:staff:cashier',
        'staff:staff:customers',
        'staff:staff:delivery_notes',
        'staff:staff:deposits',
        'staff:staff:down_payments',
        'staff:staff:expenses',
        'staff:staff:favourites',
        'staff:staff:history',
        'staff:staff:login',
        'staff:staff:logout',
        'staff:staff:logs',
        'staff:staff:orders:preview:print',
        'staff:staff:payments',
        'staff:staff:products',
        'staff:staff:receipts',
        'staff:staff:registers',
        'staff:staff:selections',
        'staff:staff:settings',
        'staff:staff:stocks',
        'staff:staff:transactions',
        'staff:staff:vouchers'
      ],
      title: vm.$t(
        'pages.staff.form.scopes.all_permissions.preselect.cashierFull'
      )
    },
    cashierSales: {
      scopes: [
        'staff:staff:cashier',
        'staff:staff:transactions',
        'staff:staff:payments',
        'staff:staff:registers'
      ],
      title: vm.$t(
        'pages.staff.form.scopes.all_permissions.preselect.cashierSales'
      )
    },
    cashierSB: {
      scopes: [
        'staff:staff:cashier',
        'staff:staff:transactions',
        'staff:staff:payments',
        'staff:staff:balance',
        'staff:staff:balances'
      ],
      title: vm.$t(
        'pages.staff.form.scopes.all_permissions.preselect.cashierSB'
      )
    },
    cashierSBD: {
      scopes: [
        'staff:staff:cashier',
        'staff:staff:transactions',
        'staff:staff:payments',
        'staff:staff:balance',
        'staff:staff:balances',
        'staff:staff:expenses',
        'staff:staff:deposits'
      ],
      title: vm.$t(
        'pages.staff.form.scopes.all_permissions.preselect.cashierSBD'
      )
    },
    salesman: {
      scopes: ['staff:staff:salesman'],
      title: vm.$t('pages.staff.form.scopes.all_permissions.preselect.salesman')
    },
    salesmanCashierFull: {
      scopes: ['staff:staff'],
      title: vm.$t(
        'pages.staff.form.scopes.all_permissions.preselect.salesmanCashierFull'
      )
    },
    salesmanCashierSales: {
      scopes: [
        'staff:staff:salesman',
        'staff:staff:cashier',
        'staff:staff:transactions',
        'staff:staff:payments',
        'staff:staff:registers'
      ],
      title: vm.$t(
        'pages.staff.form.scopes.all_permissions.preselect.salesmanCashierSales'
      )
    },
    salesmanCashierSB: {
      scopes: [
        'staff:staff:salesman',
        'staff:staff:cashier',
        'staff:staff:transactions',
        'staff:staff:payments',
        'staff:staff:balance',
        'staff:staff:balances'
      ],
      title: vm.$t(
        'pages.staff.form.scopes.all_permissions.preselect.salesmanCashierSB'
      )
    },
    salesmanCashierSBD: {
      scopes: [
        'staff:staff:salesman',
        'staff:staff:cashier',
        'staff:staff:carts',
        'staff:staff:orders',
        'staff:staff:transactions',
        'staff:staff:payments',
        'staff:staff:balance',
        'staff:staff:balances',
        'staff:staff:expenses',
        'staff:staff:deposits'
      ],
      title: vm.$t(
        'pages.staff.form.scopes.all_permissions.preselect.salesmanCashierSBD'
      )
    }
  }
}

export function isPresetTemplate(value) {
  return Object.keys(getPresetPermissions()).includes(value)
}

export function matchPresetTemplateByScoped(vm, scopes) {
  const presetPermissions = getPresetPermissions(vm)
  const presetPermissionsKeys = Object.keys(presetPermissions)
  const matchedKey = presetPermissionsKeys.find((key) =>
    compare(scopes, presetPermissions[key].scopes)
  )
  return presetPermissions[matchedKey]
}

export function getPresetFullPermissionObject(template) {
  if (!template) return
  //this is to transform top-level permission to full list of permissions.
  //e.g 'staff:staff:x' and all the children with the same root will be added
  const fullScopes = template.scopes.reduce((scopes, scope) => {
    const regEx = new RegExp(scope)
    scopes.push(...defaultScopes.filter((key) => regEx.test(key)))
    return scopes
  }, [])

  template.scopes = [...new Set(fullScopes)]
  return template
}
