<template>
  <div v-loading="staffSetup.loading" class="m-8">
    <!-- Staff information -->
    <staff-information
      ref="staff-information"
      v-model="staffSetup.current"
      :resources="resources"
      :connected-user="connectedUserSetup"
      :can-be-default="canBeDefault"
      @user-change="handleUserChange"
    />

    <!-- Staff contact -->
    <staff-contact ref="staff-contact" v-model="staffSetup.current" />

    <!-- Staff POS permissions -->
    <staff-pos-permissions
      ref="staff-permissions"
      v-model="staffSetup.current"
      :resources="resources"
      @scope-change="handleScopeChange"
    />

    <!-- Staff Metadata -->
    <staff-metadata
      v-if="hasDebugMode"
      ref="staff-metadata"
      v-model="staffSetup.current.metadata"
    />

    <!-- Reservations -->
    <staff-reservations
      v-if="$isFeatureEnabled('reservations')"
      ref="staff-reservations"
      v-model="staffSetup.current"
      :saves-count="savesCount"
      :resources="resources"
      @save="$emit('save')"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import pick from 'just-pick'
import get from 'just-safe-get'
import StaffContact from './staff-contact'
import StaffInformation from './staff-information'
import StaffPosPermissions from './staff-pos-permissions'
import StaffReservations from './reservations/staff-reservations.vue'
import StaffMetadata from './staff-metadata'
import staffModel from '../model/staff-model'
import connectedUserModel from '../model/connected-user-model'
import { genInitialData, genInitialConnectedUser } from '../helpers'
import getFullName from '@/utils/full-name'
import onboardingMixin from '@/mixins/onboarding'

export default {
  components: {
    StaffContact,
    StaffInformation,
    StaffPosPermissions,
    StaffMetadata,
    StaffReservations
  },
  emits: ['is-default', 'save'],
  setup() {
    const staffSetup = ref(staffModel.setup(genInitialData()))
    const connectedUserSetup = ref(
      connectedUserModel.setup(genInitialConnectedUser())
    )
    const { onboardingConfirmed, modifyOnboarding } = onboardingMixin()
    return {
      staffSetup,
      connectedUserSetup,
      onboardingConfirmed,
      modifyOnboarding
    }
  },
  data() {
    return {
      // Used to let child components react to successfull submits
      savesCount: 0,
      resources: {},
      fields: [
        'active',
        'addresses',
        'branch_groups',
        'date_of_birth',
        'default',
        'email',
        'firstname',
        'images',
        'lastname',
        'locations',
        'metadata',
        'phonenumbers',
        'scopes',
        'short_code',
        'staff_number'
      ]
    }
  },
  computed: {
    ...mapGetters({
      hasDebugMode: 'Config/hasGlobalDataMode',
      clientAccountConfiguration: 'Config/getClientAccountConfiguration',
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    configurationId() {
      return get(this.clientAccountConfiguration, 'id', null)
    },
    canBeDefault() {
      const template = this.resources.staffPermissionsTemplates?.find(
        (template) =>
          template.id === this.staffSetup.current.staff_permission_template_id
      )
      return (
        template?.scopes?.includes('staff:staff') ||
        template?.scopes?.includes('staff:staff:cashier') ||
        this.staffSetup.current.scopes?.includes('staff:staff') ||
        this.staffSetup.current.scopes?.includes('staff:staff:cashier')
      )
    },
    shouldGenerateStaffId() {
      return get(this.clientAccountConfiguration, 'staff.generate_staff_id')
    }
  },
  watch: {
    'staffSetup.current.user': {
      immediate: true,
      handler() {
        this.handleUserChange(this.staffSetup.current.user)
      }
    },
    'staffSetup.current.default': function (newValue) {
      this.$emit('is-default', newValue)
    },
    'staffSetup.current.scopes': function () {
      // user might have removed permissions required for being default staff.
      // therefore we force default to false, if permissions are missing
      if (this.staffSetup.current.default && !this.canBeDefault) {
        this.staffSetup.current.default = false
      }
    }
  },
  async mounted() {
    this.staffSetup.id = this.$route.params.id
    await this.staffSetup.fetch()
    await this.fetchResources()
    this.staffSetup.sync()
  },
  methods: {
    async fetchResources() {
      try {
        const {
          branchesV1 = [],
          branchGroups = [],
          staffPermissionsTemplates = [],
          productGroups = []
        } = await this.$resourceFetch(
          'branchesV1',
          'branchGroups',
          'staffPermissionsTemplates',
          {
            resource: 'productGroups',
            query: { is_service_category: true, deleted: false }
          }
        )
        this.resources = {
          branches: branchesV1,
          branchGroups,
          staffPermissionsTemplates,
          productGroups
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.multiple', {
            resources: this.$t('common.resource.resource.plural')
          })
        })
      }
    },
    async handleSubmit() {
      //validate the form
      const isValid = await this.validate()
      if (!isValid) {
        return this.$message({
          type: 'warning',
          message: this.$t(
            'pages.products.edit.form.warnings.invalid_inputs.contents'
          )
        })
      }

      const defaultStaffValid = await this.checkDefaultStaffState()
      if (!defaultStaffValid) {
        return
      }

      //save metadata to model
      this.staffSetup.current.metadata = this.getMetadata()

      //save
      const createQuery = {
        query: {
          generate_staff_id: this.shouldGenerateStaffId
        }
      }

      const isNew = this.staffSetup.isNew
      const resource = this.$t('common.resource.staff.singular')
      const { error } = await this.staffSetup.save({ createQuery })
      if (error) {
        //save fail
        const errorMessage = isNew
          ? this.$t('common.error.action.create.single', { resource })
          : this.$t('common.error.action.update.single', { resource })

        this.$logException(error, {
          message: errorMessage,
          trackError: false
        })
        return
      }

      this.savesCount++

      //save success
      const successMessage = isNew
        ? this.$t('common.success.action.create.single', { resource })
        : this.$t('common.success.action.update.single', { resource })
      this.$message({
        type: 'success',
        message: successMessage
      })

      // Modify onboarding, in case the step is not confirmed yet
      if (!this.onboardingConfirmed('staff')) {
        await this.modifyOnboarding('staff')
      }

      if (isNew) {
        if (this.navigationAfterCreation === 'edit' && this.staffSetup.id) {
          this.routeTo(this.staffSetup.id)
        } else {
          this.routeTo()
        }
      }
    },
    getMetadata() {
      return this.$refs['staff-metadata']?.getValue() || {}
    },
    async handleUserChange(user) {
      //reset data
      this.connectedUserSetup.reset()
      //fetch user
      this.connectedUserSetup.id = user
      if (this.configurationId) {
        await this.connectedUserSetup.fetch(this.configurationId)
      }
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(
        this.$formatCustomer(this.staffSetup.current) || this.staffSetup.id
      )
      if (confirm) this.delete()
    },
    async delete() {
      if (this.connectedUser) {
        await th.staff().makeUser(this.$route.params.id, { user: null })
      }
      const { error } = await this.staffSetup.deleteModel()
      if (error) {
        //Delete failed
        const errorMessage = this.$t('common.error.action.delete.single', {
          resource: this.$t('common.resource.staff.singular')
        })

        this.$logException(error, { message: errorMessage })
        return
      }

      //Delete success
      this.routeTo()
    },
    async handleScopeChange() {
      // user might have removed permissions required for being default staff.
      // therefore we force default to false, if permissions are missing
      if (this.staffSetup.current.default && !this.canBeDefault) {
        this.staffSetup.current.default = false
      }
      await this.fetchResources()
    },
    routeTo(path) {
      this.staffSetup.reset() //this will make model clean (not dirty)
      const routePath = `/staff/manager${path ? '/' + path + '/edit' : ''}`
      this.$router.push(routePath)
    },
    async checkDefaultStaffState() {
      // first, check if `default` property has been changed by the user
      if (
        this.staffSetup.current.default !== this.staffSetup.original.default
      ) {
        if (this.staffSetup.current.default) {
          const isSuccess = await this.unsetDefaultStaff()
          if (!isSuccess) {
            // failed to set as default staff (either request failed or user cancelled)
            return false
          }
        } else {
          // ask for confirmation to unset default staff
          try {
            await this.$confirm(
              this.$t(
                'pages.staff.edit.form.confirm_unset_as_default.description'
              ),
              this.$t('pages.staff.edit.form.confirm_unset_as_default.title'),
              {
                confirmButtonText: this.$t('common.interactions.buttons.yes'),
                cancelButtonText: this.$t('common.interactions.buttons.no'),
                type: 'warning'
              }
            )
          } catch (e) {
            // user cancelled/declined
            return false
          }
        }
      }
      return true
    },
    /**
     * Unsets the default staff member. Required, if you want to set the currently loaded member as the new default.
     *
     * Returns `true` if fetching worked and user acknowledged, `false` otherwise.
     * Does not throw, errors are handled by the function.
     * @returns {Promise<boolean>}
     */
    async unsetDefaultStaff() {
      // fetch current default staff
      let currentDefaultStaff
      try {
        const { data } = await th.staff().getAll({ default: true })
        currentDefaultStaff = data[0] // could be undefined
      } catch (e) {
        const message = this.$t('common.error.action.update.multiple', {
          resources: this.$t('common.resource.staff.singular')
        })
        this.$logException(e, { message })
        return false
      }

      // check if default staff exists and warn the user
      if (currentDefaultStaff) {
        const oldStaff = getFullName({
          firstname: currentDefaultStaff.firstname,
          lastname: currentDefaultStaff.lastname
        })
        const newStaff = getFullName({
          firstname: this.staffSetup.current.firstname,
          lastname: this.staffSetup.current.lastname
        })

        try {
          await this.$confirm(
            this.$t(
              'pages.staff.edit.form.confirm_set_as_default.description',
              {
                oldStaff,
                newStaff
              }
            ),
            this.$t('pages.staff.edit.form.confirm_set_as_default.title'),
            {
              confirmButtonText: this.$t('common.interactions.buttons.yes'),
              cancelButtonText: this.$t('common.interactions.buttons.no'),
              type: 'warning'
            }
          )
        } catch (e) {
          // do nothing and return
          return false
        }

        // we got past the dialog, so let's first unset the current default staff
        try {
          currentDefaultStaff.default = false
          currentDefaultStaff.metadata = currentDefaultStaff.metadata || {}
          await th
            .staff()
            .put(currentDefaultStaff.id, pick(currentDefaultStaff, this.fields))
        } catch (e) {
          const errorMessage = this.$t('common.error.action.update.multiple', {
            resources: this.$t('common.resource.staff.plural')
          })
          this.$logException(e, {
            message: errorMessage
          })
          return false
        }
      }
      return true
    },
    async validate() {
      //validate staff information
      const informationValid = await this.$refs['staff-information'].validate()
      const reservationsValid = this.$refs['staff-reservations']
        ? await this.$refs['staff-reservations'].validate()
        : true

      //validate metadata information
      const metadataValid = this.$refs['staff-metadata']
        ? await this.$refs['staff-metadata'].validate()
        : true

      return informationValid && reservationsValid && metadataValid
    }
  }
}
</script>
