<template>
  <th-wrapper collapsable :title="$t('common.titles.general_info.title')">
    <el-form ref="form" :model="form" :rules="rules">
      <el-row :gutter="10">
        <el-col :md="9">
          <!-- First name -->
          <el-col>
            <el-form-item
              prop="firstname"
              :label="$t('common.forms.labels.firstname')"
            >
              <el-input
                id="firstname"
                v-model="form.firstname"
                :placeholder="$t('common.forms.labels.firstname')"
              />
            </el-form-item>
          </el-col>
          <!-- Last name -->
          <el-col>
            <el-form-item
              prop="lastname"
              :label="$t('common.forms.labels.lastname')"
            >
              <el-input
                id="lastname"
                v-model="form.lastname"
                :placeholder="$t('common.forms.labels.lastname')"
              />
            </el-form-item>
          </el-col>
          <!-- Available in -->
          <el-col>
            <el-form-item
              for="locations"
              :label="$t('common.forms.labels.locations')"
            >
              <available-in
                id="locations"
                hide-available-nowhere
                :model-value="{
                  locations: form.locations,
                  branch_groups: form.branch_groups
                }"
                :resources="resources"
                :show-items-limit="2"
                @update:modelValue="handleAvailableInInput"
              />
            </el-form-item>
          </el-col>
          <!-- Date of birth -->
          <el-col :md="12">
            <el-form-item
              prop="date_of_birth"
              :label="$t('common.forms.labels.date_of_birth')"
              class="w-full"
            >
              <date-picker-input
                v-model="form.date_of_birth"
                class="w-full"
                no-future-dates
                :placeholder="$t('pages.staff.form.placeholder.select_date')"
              />
            </el-form-item>
          </el-col>
          <!-- External Refrence ID -->
          <el-col :md="12">
            <el-form-item
              prop="external_reference_id"
              :label="$t('pages.staff.form.external_reference_id')"
            >
              <el-input
                id="external_reference_id"
                v-model="form.external_reference_id"
                :placeholder="$t('pages.staff.form.external_reference_id')"
              />
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :md="15">
          <el-row :gutter="10">
            <el-col :md="15">
              <!-- Gender -->
              <el-col>
                <el-form-item
                  prop="gender"
                  :label="$t('common.forms.labels.gender')"
                >
                  <gender-select
                    id="gender"
                    v-model="form.gender"
                    :placeholder="$t('common.forms.labels.gender')"
                  />
                </el-form-item>
              </el-col>
              <!-- Staff number -->
              <el-col>
                <el-form-item
                  prop="staff_number"
                  :label="$t('pages.staff.form.staff_number')"
                >
                  <el-input
                    id="staff_number"
                    v-model="form.staff_number"
                    :disabled="generateStaffNumber"
                    :placeholder="
                      generateStaffNumber
                        ? $t('pages.staff.form.hint.staff_id.is_auto')
                        : $t('pages.staff.form.staff_number')
                    "
                  />
                </el-form-item>
              </el-col>
              <!-- PIN generator -->
              <el-col>
                <el-form-item
                  prop="short_code"
                  :label="$t('pages.staff.form.pin.title')"
                >
                  <pin-generator
                    id="short_code"
                    class="w-full"
                    :form-pin="form.short_code"
                    :update-pin="updatePin"
                  />
                </el-form-item>
              </el-col>
            </el-col>
            <el-col :md="8">
              <!-- Staff image -->
              <el-form-item
                prop="images"
                class="mb-0"
                :label="$t('common.forms.labels.image')"
              >
                <th-image-upload-v2
                  id="images"
                  v-model="form.images"
                  class="h-56"
                  resource="staff"
                  image-key="1x"
                  fill-type="contain"
                  icon="PictureRounded"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-col class="flex justify-between">
            <!-- Connect user -->
            <el-form-item
              prop="comment"
              :label="$t('pages.staff.form.headers.user')"
            >
              <make-staff-user
                :is-new="isNew"
                :initially-connected-user="connectedUser.id"
                @user-change="$emit('user-change', $event)"
              />
            </el-form-item>
            <!-- default user -->
            <el-checkbox
              v-if="canBeDefault || form.default"
              v-model="form.default"
              class="m-8"
              :label="$t('pages.staff.edit.form.labels.default_staff')"
            />
            <!-- Active -->
            <el-checkbox
              v-model="form.active"
              class="m-8"
              :label="$t('common.forms.labels.active')"
            />
            <!-- Owner -->
            <div class="flex m-8">
              <th-popover
                :text="$t('pages.staff.edit.form.labels.owner_staff_tooltip')"
                class="mr-2"
              />
              <el-checkbox id="owner" v-model="form.owner">
                {{ $t('pages.staff.edit.form.labels.owner_staff') }}
              </el-checkbox>
            </div>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'
import isEmpty from 'just-is-empty'
import { mapGetters } from 'vuex'
import AvailableIn from '@/components/available-in'
import DatePickerInput from '@/components/inputs/date-picker'
import GenderSelect from '@/components/select/gender-select'
import PinGenerator from '@/components/pin-generator'
import MakeStaffUser from './make-staff-user'

export default {
  components: {
    AvailableIn,
    DatePickerInput,
    GenderSelect,
    PinGenerator,
    MakeStaffUser
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    resources: {
      type: Object,
      default: () => ({})
    },
    connectedUser: {
      type: Object,
      default: () => ({})
    },
    canBeDefault: {
      type: Boolean,
      default: false
    }
  },
  emits: ['user-change', 'update:modelValue'],
  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration'
    }),
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    isNew() {
      // cheat
      if (this.$route.params.id && this.$route.params.id === 'new') return true

      return !this.$route.params.id
    },
    generateStaffNumber() {
      return get(
        this.clientAccountConfiguration,
        'staff.generate_staff_id',
        false
      )
    },
    rules() {
      return {
        firstname: [
          {
            max: 32,
            message: this.$t(
              'pages.staff.edit.form.field_warnings.max_length',
              { length: 32 }
            )
          }
        ],
        lastname: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          },
          {
            min: 1,
            max: 128,
            message: this.$t(
              'pages.staff.edit.form.field_warnings.max_length',
              { length: 128 }
            )
          }
        ],
        email: [
          {
            type: 'email',
            message: this.$t('pages.staff.edit.form.field_warnings.type.email')
          },
          {
            max: 128,
            message: this.$t(
              'pages.staff.edit.form.field_warnings.max_length',
              { length: 128 }
            )
          }
        ],
        staff_number: [
          {
            required: !this.generateStaffNumber,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          }
        ],
        short_code: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  methods: {
    updatePin(pin) {
      this.form.short_code = pin
    },
    handleAvailableInInput({ locations, branch_groups }) {
      this.form.locations = !isEmpty(locations) ? locations : null //prevent empty array to be saved (aka AvailableNowhere)
      this.form.branch_groups = branch_groups
    },
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
