<template>
  <div>
    <div class="color-picker flex items-center" data-testid="calendar-color">
      <th-input-title class="mr-4 pb-0">
        {{ $t('pages.staff.form.reservations.calendar_color.label') }}
      </th-input-title>

      <el-color-picker
        v-model="calendarColor"
        class="hidden"
        color-format="hex"
        :predefine="defaultCalendarColors"
      />
    </div>

    <span class="block text-sm mt-4">
      {{ $t('pages.staff.form.reservations.calendar_color.explainer') }}
    </span>
  </div>
</template>

<script>
import { defaultCalendarColors } from '../../helpers'

export default {
  name: 'StaffSettings',
  props: {
    employee: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      defaultCalendarColors
    }
  },
  computed: {
    calendarColor: {
      get() {
        return this.form.calendar_color
      },
      set(value) {
        // Cannot disable removing colors from the color picker, so if it emits
        // an empty value, we won't update it.
        if (value) {
          this.form.calendar_color = value
        }
      }
    },
    form() {
      return this.employee
    }
  }
}
</script>

<style scoped>
.color-picker:deep(.el-color-picker__trigger) {
  width: 64px;
}
</style>
