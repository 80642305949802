<template>
  <th-wrapper collapsable :title="$t('pages.staff.form.headers.metadata')">
    <div class="h-64">
      <json-editor
        ref="metadataEditor"
        :json-input="modelValue"
        @jsonChanged="metadataChanged"
      />
    </div>
  </th-wrapper>
</template>

<script>
import JsonEditor from '@/components/json-editor'

export default {
  components: { JsonEditor },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      localValue: null
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.localValue = JSON.stringify(this.modelValue)
      }
    }
  },
  methods: {
    metadataChanged(value) {
      this.localValue = value
      this.validate()
    },
    getValue() {
      return this.localValue.replace(/\s/g, '') !== ''
        ? JSON.parse(this.localValue)
        : {}
    },
    async validate() {
      return await this.$refs.metadataEditor.validateJson()
    }
  }
}
</script>
