<template>
  <el-row type="flex">
    <el-select
      v-model="localValue"
      :placeholder="$t('common.interactions.buttons.select')"
      clearable
      @change="selectPresetPermissions"
    >
      <el-option-group
        v-for="group in getPresetPermissions"
        :key="group.label"
        :label="group.label"
      >
        <el-option
          v-for="item in group.options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-option-group>
    </el-select>
    <el-button
      text
      class="focus:outline-none px-4"
      @click="$emit('open-permission-editor')"
      v-text="buttonText"
    />
  </el-row>
</template>

<script>
import {
  defaultScopes,
  base,
  getPresetPermissions
} from './permissions-constants'
import { permissionsConfigStaff } from '@/constants/permissions-constants'
const initialPermissions = defaultScopes.reduce((obj, key) => {
  obj[key] = false
  return obj
}, {})

export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    createTemplatePreset: {
      type: Object,
      required: false,
      default: () => ({})
    },
    extendPresets: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isBasePreset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      permissionsConfigStaff: permissionsConfigStaff(this),
      presetPermissions: getPresetPermissions(this),
      all: base,
      permissions: { ...initialPermissions }
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    allPresets() {
      return {
        ...this.createTemplatePreset,
        ...this.extendPresets,
        ...this.presetPermissions
      }
    },
    getPresetPermissions() {
      const presetGroups = [
        {
          label: '',
          options: { ...this.createTemplatePreset }
        },
        {
          label: this.$t(
            'pages.staff.form.scopes.all_permissions.presets.custom'
          ),
          options: { ...this.extendPresets }
        },
        {
          label: this.$t(
            'pages.staff.form.scopes.all_permissions.presets.base'
          ),
          options: { ...this.presetPermissions }
        }
      ]
      return presetGroups.reduce((accGroups, group) => {
        const optionsKeys = Object.keys(group.options)
        if (optionsKeys.length) {
          const options = optionsKeys.map((key) => {
            return {
              value: key,
              label: this.allPresets[key].title
            }
          })
          return accGroups.concat({ ...group, options })
        }
        return accGroups
      }, [])
    },
    buttonText() {
      //preset template selected
      if (this.isBasePreset) {
        return this.$t(
          'pages.staff.form.scopes.all_permissions.preselect.show_permissions'
        )
      }

      //custom template selected
      if (this.localValue) {
        return this.$t(
          'pages.staff.form.scopes.all_permissions.preselect.configure_permissions'
        )
      }

      //no template selected
      return null
    }
  },
  methods: {
    selectPresetPermissions(value) {
      const preset = value && this.allPresets[value]
      if (preset) {
        this.$emit('update-template-id', preset.id || '')
        if (preset.emitEvent) {
          this.$emit(preset.emitEvent)
          this.$emit('update-selection', { name: '', scopes: [] })
          return
        }
        this.$emit('update-selection', preset)
      } else {
        //reset permissions
        this.$emit('update-selection', { scopes: [] })
      }
    }
  }
}
</script>
