<template>
  <th-wrapper
    collapsable
    :title="$t('pages.staff.form.headers.pos_permissions')"
    :subtitle="$t('pages.staff.form.scopes.all_permissions.presets.info')"
  >
    <permissions
      v-model="selectedPreset"
      :scopes="form.scopes"
      :extend-presets="extendPresetsOptions"
      :create-template-preset="createTemplatePreset"
      :is-base-preset="isBasePreset"
      @update-template-id="selectedTemplate = $event"
      @update-selection="updatePreset"
      @open-permission-editor="showModal"
    />
    <modal v-model="isModalOpen" height="90%" width="85%">
      <el-container class="p-8 bg-th-background-color">
        <el-header class="flex justify-between items-center p-0 mb-4 h-8">
          <div class="text-lg" v-text="$t('pages.permissions.title')" />
          <el-button
            text
            class="text-lg text-gray-600"
            icon="Close"
            @click="hideModal"
          />
        </el-header>
        <el-main class="p-0">
          <permissions-view
            v-model="form"
            template-type="staff"
            :is-base-preset="isBasePreset"
            :preset-template="fullPermissionPresetTemplate"
            :subtitle="$t('pages.permissions.staff.subtitle')"
            :template-id="selectedTemplate"
            :resources="resources"
            @scopes-change="handleScopeChange"
            @close="hideModal"
          />
        </el-main>
      </el-container>
    </modal>
  </th-wrapper>
</template>

<script>
import compare from 'just-compare'
import Permissions from './permissions'
import PermissionsView from '@/components/permissions-view'
import {
  matchPresetTemplateByScoped,
  getPresetFullPermissionObject
} from './permissions-constants'

export default {
  components: {
    Permissions,
    PermissionsView
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedTemplate: '',
      selectedPreset: '',
      isBasePreset: false,
      isModalOpen: false
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    extendPresetsOptions() {
      return this.resources.staffPermissionsTemplates?.reduce(
        (templatesObj, { id, name, scopes }) => {
          templatesObj[name] = {
            id,
            scopes,
            title: name
          }
          return templatesObj
        },
        {}
      )
    },
    presetTemplate() {
      const template = matchPresetTemplateByScoped(this, this.form.scopes)
      return template ? { name: template.title, scopes: template.scopes } : null
    },
    fullPermissionPresetTemplate() {
      return getPresetFullPermissionObject(this.presetTemplate)
    },
    createTemplatePreset() {
      return {
        create_template: {
          title: this.$t(
            'pages.staff.form.scopes.all_permissions.preselect.create_template'
          ),
          emitEvent: 'open-permission-editor'
        }
      }
    }
  },
  watch: {
    'form.scopes'(newScopes, oldScopes) {
      if (!compare(newScopes, oldScopes)) {
        this.matchScopesToPermission()
      }
    },
    'form.staff_permission_template_id': 'matchScopesToPermission',
    'resources.staffPermissionsTemplates': 'matchScopesToPermission'
  },
  methods: {
    showModal() {
      this.isModalOpen = true
    },
    hideModal() {
      this.isModalOpen = false
    },
    matchScopesToPermission() {
      this.isBasePreset = false
      //Handle custom template
      const selectedTemplateId = this.form.staff_permission_template_id
      if (selectedTemplateId) {
        const selectedTemplate = this.resources.staffPermissionsTemplates?.find(
          (template) => template.id === selectedTemplateId
        )
        if (selectedTemplate) {
          this.updatePreset(selectedTemplate)
          return
        }
      }

      //Handle preset template(match scopes to preset)
      if (this.presetTemplate) {
        this.isBasePreset = true
        this.updatePreset(this.presetTemplate)
      }
    },
    updateStaffPermissions({ id, scopes = [] }) {
      //Staff permissions has either template ID or Scopes
      const templateId = id || null
      const scopesList = templateId ? [] : scopes

      this.form.staff_permission_template_id = id
      this.form.scopes = scopesList //override the scopes
    },
    async handleScopeChange(preset) {
      this.$emit('scope-change')
      this.updatePreset(preset)
      this.hideModal()
    },
    updatePreset({ name, scopes, id = null } = {}) {
      if (typeof name === 'string') {
        this.selectedPreset = name
      }

      if (id && typeof id === 'string') {
        this.selectedTemplate = id
      }

      this.updateStaffPermissions({ id, scopes })
    }
  }
}
</script>
