<template>
  <div>
    <el-button
      v-if="!displayUser"
      :class="{ 'el-button--primary is-plain': isWhiteLabel }"
      @click="$thModal.show('connect-user')"
    >
      {{ $t('pages.staff.form.make_user.buttons.main') }}
    </el-button>

    <div v-else-if="displayUser" v-loading="loading" class="flex items-center">
      <router-link
        :to="{ name: 'settings-users-edit', params: { id: user.id } }"
        class="text-th-primary-blue hover:underline leading-relaxed"
      >
        {{ userDetails }}
      </router-link>

      <el-button
        plain
        class="flex-shrink-0 el-button--text-icon"
        icon="Delete"
        @click="removeCurrentConnectedStaff()"
      />
    </div>

    <th-modal
      name="connect-user"
      width="400px"
      :title="$t('pages.staff.form.make_user.dialog.title')"
    >
      <th-input-title :title="$t('pages.staff.form.make_user.select')" />
      <remote-search-select
        v-model="connectedUser"
        :modify-query="modifyQuery"
        :result-filter="resultFilter"
        :resource-id="configurationId"
        resource="users"
        fetch-handler="getAll"
        class="w-full"
        :compute-name="getName"
        popper-append-to-body
        @resource-set="handleUserChanged"
      />
    </th-modal>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import getFullName from '@/utils/full-name'
import RemoteSearchSelect from '@/components/select/remote-search'
import { isUnifiedCommerce } from '@/constants'

export default {
  components: {
    RemoteSearchSelect
  },
  props: {
    isNew: {
      type: Boolean,
      required: true
    },
    initiallyConnectedUser: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      connectedUser: undefined,
      user: null,
      loading: false
    }
  },
  computed: {
    configurationId() {
      return (
        safeGet(this.$store.state.Config, 'clientAccountConfiguration.id') ||
        null
      )
    },
    displayUser: {
      get() {
        return this.user
      },
      set(value) {
        this.user = value
        this.$emit('user-change', value?.id)
      }
    },
    userDetails() {
      if (!this.displayUser) return
      return this.getName(this.displayUser)
    },

    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  watch: {
    initiallyConnectedUser: function (newUser, oldUser) {
      if (newUser && newUser !== oldUser) {
        this.getOneUser(newUser)
      }
    }
  },
  methods: {
    async getOneUser(userId) {
      const errorMessage = this.$t('common.error.action.read.single', {
        resource: this.$t('common.resource.staff.singular')
      })
      this.loading = true
      try {
        const { data = [] } = await th.users(this.configurationId).get(userId)
        this.displayUser = data
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    // Adding the deleted false to the query
    modifyQuery(queryText) {
      return { query: { deleted: false, q: queryText } }
    },
    // should filter the users that are already assigned, but the functionality doesn't exist yet
    resultFilter(results) {
      return results.sort((a, b) =>
        this.getName(a).localeCompare(this.getName(b))
      )
    },
    handleDialogClose() {
      this.connectedUser = null
      this.$thModal.hide('connect-user')
    },
    async handleUserChanged(user) {
      if (this.isNew) {
        this.displayUser = user
        this.handleDialogClose()
        return
      }

      const successMessage = this.$t('common.success.action.update.single', {
        resource: this.$t('common.resource.staff.singular')
      })
      const errorMessage = this.$t('common.error.action.update.single', {
        resource: this.$t('common.resource.staff.singular')
      })

      try {
        const { data = {} } = await th
          .staff()
          .makeUser(this.$route.params.id, { user: user.id })
        if (data.id) {
          this.displayUser = user
          this.$message({
            type: 'success',
            message: successMessage
          })
        }
        this.handleDialogClose()
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    async removeCurrentConnectedStaff() {
      if (this.isNew) {
        this.displayUser = null
        return
      }

      const successMessage = this.$t('common.success.action.update.single', {
        resource: this.$t('common.resource.staff.singular')
      })
      const errorMessage = this.$t('common.error.action.update.single', {
        resource: this.$t('common.resource.staff.singular')
      })

      try {
        await th.staff().makeUser(this.$route.params.id, { user: null })
        this.displayUser = null
        this.$message({
          type: 'success',
          message: successMessage
        })
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    getName(user) {
      const name = getFullName(user).trim()
      return name || this.$formatStaff(user, ['username']) || ''
    }
  }
}
</script>
<style scoped>
.el-popper {
  width: 260px;
}
</style>
