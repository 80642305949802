<template>
  <div
    class="add-shift-row"
    :class="{
      'flex flex-col justify-center items-start': mode === 'edit'
    }"
  >
    <individual-shifts
      v-for="(shift, index) in shiftsArray"
      :key="index"
      class="mb-2"
      :shift="shift"
      :index="index"
      :mode="mode"
      @remove="removeShift(index)"
    />

    <button
      v-if="mode === 'edit'"
      class="inline-flex items-center justify-center"
      type="button"
      @click="addShift"
    >
      <el-icon class="text-th-primary text-xl">
        <plus />
      </el-icon>
    </button>
  </div>
</template>

<script>
import IndividualShifts from './individual-shifts.vue'

export default {
  name: 'AddShift',
  components: {
    IndividualShifts
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    addedShifts: {
      type: Array,
      required: true
    }
  },
  computed: {
    shiftsArray() {
      return this.addedShifts
    }
  },
  methods: {
    addShift() {
      this.shiftsArray.push({
        start: '09:00',
        end: '18:00'
      })
    },
    removeShift(shift) {
      this.shiftsArray.splice(shift, 1)
    }
  }
}
</script>

<style scoped>
.add-shift-row {
  min-height: var(--input-height);
}
</style>
