<template>
  <div class="w-full" data-testid="available-in-holder">
    <el-button class="mr-2" icon="Plus" @click="openModal">
      {{ $t('common.interactions.buttons.add') }}
    </el-button>

    <th-modal
      :name="modalName"
      width="1200px"
      :title="$t('pages.staff.form.reservations.select_services')"
      height="800px"
      body-class="flex flex-col"
      data-testid="available-in-modal"
      @close="cancelModal"
    >
      <div class="overflow-hidden">
        <staff-services-group-items-select v-model="localServices" />
      </div>

      <div class="flex justify-end shadow-md p-4 bg-th-light-blue">
        <!-- Make available everywhere -->
        <el-button @click="selectPerformAllServicesOption">
          {{ $t('pages.staff.form.reservations.perform_all_services') }}
        </el-button>
      </div>

      <template #footer>
        <el-button @click="cancelModal">
          {{ $t('common.interactions.buttons.cancel') }}
        </el-button>

        <el-button type="primary" @click="saveModal">
          {{ $t('common.interactions.buttons.confirm') }}
        </el-button>
      </template>
    </th-modal>
  </div>
</template>

<script>
import StaffServicesGroupItemsSelect from './staff-services-group-items-select.vue'
import isEmptyArray from 'just-is-empty'

export default {
  name: 'StaffServicesModal',

  components: {
    StaffServicesGroupItemsSelect
  },

  props: {
    modelValue: {
      type: Array,
      required: false,
      default: undefined
    }
  },

  data() {
    return {
      modalName: 'service-selector-modal',
      localServices: []
    }
  },

  computed: {
    isNone() {
      return isEmptyArray(this.modelValue)
    },
    isAll() {
      return this.modelValue === null
    }
  },

  methods: {
    selectPerformAllServicesOption() {
      this.localServices = null
      this.saveModal()
    },
    openModal() {
      this.localServices = this.modelValue
      this.$thModal.show(this.modalName)
    },
    cancelModal() {
      this.$thModal.hide(this.modalName)
    },
    saveModal() {
      this.$emit('update:modelValue', this.localServices)
      this.$thModal.hide(this.modalName)
    }
  }
}
</script>
