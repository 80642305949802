import { genInitialAddress } from '@/utils/address'

export function genInitialData() {
  return {
    active: true,
    addresses: [{ ...genInitialAddress(), type: 'home' }],
    branch_groups: null,
    date_of_birth: null,
    default: false,
    email: null,
    external_reference_id: null,
    firstname: null,
    gender: null,
    images: {},
    lastname: null,
    locations: null,
    metadata: {},
    owner: false,
    calendar_color: getRandomCalendarColor(),
    // Employees can perform all services by default
    services: null,
    phonenumbers: [], //server expect object, data will be mutated to object before save
    scopes: [],
    short_code: '',
    staff_number: null,
    staff_permission_template_id: null,
    user: null,
    individual_reservation_times: false,
    individual_work_plan: genInitialIndividualWorkPlan()
  }
}

export function genInitialConnectedUser() {
  return {
    user_permission_template_id: null,
    username: null
  }
}

export function genInitialIndividualWorkPlan() {
  return [
    {
      day_index: 0,
      time_ranges: []
    },
    {
      day_index: 1,
      time_ranges: []
    },
    {
      day_index: 2,
      time_ranges: []
    },
    {
      day_index: 3,
      time_ranges: []
    },
    {
      day_index: 4,
      time_ranges: []
    },
    {
      day_index: 5,
      time_ranges: []
    },
    {
      day_index: 6,
      time_ranges: []
    }
  ]
}

export const defaultCalendarColors = [
  '#A9D9FB',
  '#FFCCF7',
  '#FFD4AC',
  '#E1EFFF',
  '#CEEDE9',
  '#D1FDFA',
  '#DBF9D6',
  '#EBE1FF',
  '#FFECEB',
  '#FFE8AE'
]

function getRandomCalendarColor() {
  return defaultCalendarColors[
    Math.floor(Math.random() * defaultCalendarColors.length)
  ]
}

export const addressTypes = ['home']

export const PICKED_FIELDS = [
  'active',
  'addresses',
  'branch_groups',
  'date_of_birth',
  'default',
  'email',
  'external_reference_id',
  'firstname',
  'gender',
  'images',
  'lastname',
  'locations',
  'metadata',
  'owner',
  'phonenumbers',
  'scopes',
  'short_code',
  'staff_number',
  'staff_permission_template_id',
  'individual_reservation_times',
  'individual_work_plan',
  'calendar_color',
  'services'
]
