<template>
  <div class="time-range-select flex items-center">
    <template v-if="mode === 'view'">
      <p class="mb-1 mt-0 leading-4">{{ shift.start }} - {{ shift.end }}</p>
    </template>

    <template v-else>
      <time-select
        v-model="localShift.start"
        size="small"
        start="00:00"
        end="23:45"
        :clearable="false"
        :step="step"
        @update:model-value="validateEndsAtField"
      />

      <span class="mx-2 whitespace-no-wrap">
        {{ $t('pages.staff.shift_plan.form.week_selector.to') }}
      </span>

      <el-form-item
        ref="endsAtField"
        :prop="`${index}.ends_at`"
        :rules="endsAtValidationRule"
        :show-message="false"
        class="block mb-0 leading-none"
      >
        <time-select
          v-model="localShift.end"
          size="small"
          :clearable="false"
          :start="localShift.start"
          end="23:45"
          :step="step"
        />
      </el-form-item>
      <button
        class="ml-2 text-lg leading-none inline-flex items-center"
        type="button"
        @click="onRemove"
      >
        <el-icon>
          <close />
        </el-icon>
      </button>
    </template>
  </div>
</template>

<script>
import TimeSelect from '@/components/time-select/index.vue'

export default {
  name: 'IndividualShifts',
  components: { TimeSelect },
  props: {
    mode: {
      type: String,
      required: true
    },
    shift: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  emits: ['remove'],
  data() {
    return {
      step: '00:15',
      endsAtValidationRule: {
        validator: this.endsAtValidationFn,
        trigger: 'change'
      }
    }
  },
  computed: {
    localShift() {
      return this.shift
    }
  },
  methods: {
    validateEndsAtField() {
      this.$refs.endsAtField.validate(null, () => {})
    },

    endsAtValidationFn(_, __, callback) {
      const { start, end } = this.localShift

      const endsBeforeStarts = end <= start
      if (!endsBeforeStarts) {
        return callback()
      }

      const error = new Error(
        this.$t(
          'pages.staff.shift_plan.form.errors.shift_start_time_is_after_end_time'
        )
      )

      callback(error)

      this.$message({
        type: 'error',
        message: error.message,
        grouping: true
      })
    },

    onRemove() {
      this.$emit('remove', this.localShift)
    }
  }
}
</script>
