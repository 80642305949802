<template>
  <div>
    <hr class="mt-6 mb-10" />
    <div>
      <h5 class="mt-0 mb-4 text-th-color-header">
        {{ $t('pages.staff.form.reservations.availability.title') }}
      </h5>

      <multi-line-text
        class="text-sm mb-4"
        :text="$t('pages.staff.form.reservations.availability.explainer')"
      />

      <div class="relative">
        <el-form ref="staff-availability-form" :model="form">
          <el-form-item prop="individual_reservation_times">
            <el-switch
              id="individual_reservation_times"
              v-model="form.individual_reservation_times"
              :active-text="
                $t(
                  'pages.staff.form.reservations.availability.individual.times'
                )
              "
              @change="toggleIndividualReservationTimes"
            />
          </el-form-item>

          <el-table
            v-if="form.individual_reservation_times"
            class="mb-8"
            :data="individualWorkPlan"
          >
            <el-table-column
              v-for="(day, index) in individualWorkPlan[0].days"
              :key="index"
              min-width="295"
              prop="label"
              :label="$t('common.days.' + weekDays[index])"
              class-name="align-top"
            >
              <add-shift :mode="mode" :added-shifts="day.time_ranges" />
            </el-table-column>

            <el-table-column fixed="right" min-width="50">
              <button
                v-if="mode === 'view'"
                type="button"
                class="w-6 h-6 rounded flex items-center justify-center"
                @click="mode = 'edit'"
              >
                <svgicon
                  :src="require('@/assets/icons/th-pen.svg')"
                  :style="{ height: '20px', width: '20px' }"
                  class="text-th-primary"
                />
              </button>

              <button
                v-else
                type="button"
                class="w-6 h-6 rounded flex items-center justify-center"
                @click="handleSave"
              >
                <svgicon
                  :src="require('@/assets/icons/th-save.svg')"
                  :style="{ height: '18px', width: '18px' }"
                  class="text-th-primary"
                />
              </button>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import MultiLineText from '@/components/multi-line-text'
import AddShift from './add-shift.vue'
import { genInitialIndividualWorkPlan } from '../../helpers'

export default {
  name: 'StaffAvailability',
  components: {
    AddShift,
    MultiLineText
  },
  props: {
    savesCount: {
      type: Number,
      required: true
    },
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:modelValue', 'save'],
  data() {
    return {
      mode: 'view',
      weekDays: [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday'
      ]
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    individualWorkPlan() {
      return [
        {
          days: this.form.individual_work_plan
        }
      ]
    }
  },

  watch: {
    savesCount() {
      this.mode = 'view'
    }
  },
  methods: {
    validate() {
      return new Promise((resolve) => {
        this.$refs['staff-availability-form'].validate(resolve)
      })
    },
    handleSave() {
      // Emit save events up to the root element which will submit the form
      this.$emit('save')
    },
    toggleIndividualReservationTimes(isActive) {
      this.$ampli.eventWithBaseProps('individualReservationTimesToggled', {
        is_activated: isActive
      })
    }
  }
}
</script>
