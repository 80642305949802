import baseModel from '@/models/base-model-new'
import pick from 'just-pick'
import { STAFF_PHONE_TYPES } from '@/constants'
import { PICKED_FIELDS, genInitialIndividualWorkPlan } from '../helpers'
import { genInitialAddress } from '@/utils/address'

export default {
  setup(model) {
    function parseResponse(response) {
      //transform phonenumbers from object to array
      const data = response?.data || {}

      const phonenumbers = Object.values(STAFF_PHONE_TYPES).reduce(
        (acc, phone_type) => {
          if (data.phonenumbers?.[phone_type]) {
            acc.push({
              phone_type,
              phone_number: data.phonenumbers[phone_type]
            })
          }
          return acc
        },
        []
      )

      //initial addresses if null or empty
      if (!data?.addresses?.length) {
        data.addresses = [{ ...genInitialAddress(), type: 'home' }]
      }

      if (!data.individual_work_plan?.length) {
        data.individual_work_plan = genInitialIndividualWorkPlan()
      }

      return {
        ...data,
        phonenumbers
      }
    }

    function parseDataBeforeSave(data) {
      const workPlan = data.individual_work_plan.flatMap(
        (day) => day.time_ranges
      )

      if (workPlan.length === 0) {
        data.individual_reservation_times = false
      }

      // Clean empty field
      if (!data.firstname) data.firstname = null
      //transform phonenumbers from object to array before saving
      const phonenumbers = data.phonenumbers.reduce(
        (acc, { phone_type, phone_number }) => {
          if (phone_type && phone_number) {
            acc[phone_type] = phone_number
          }
          return acc
        },
        {}
      )

      const payload = {
        ...data,
        phonenumbers: Object.keys(phonenumbers).length ? phonenumbers : null
      }

      return pick(payload, PICKED_FIELDS)
    }

    const methods = { parseResponse, parseDataBeforeSave }

    return baseModel.setup(model, 'staff', methods)
  }
}
