<template>
  <th-page-wrapper
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <staff-form
      ref="form"
      @is-default="isDefault = $event"
      @save="handleSubmit"
    />
  </th-page-wrapper>
</template>

<script>
import StaffForm from './components/form.vue'

export default {
  name: 'StaffEdit',
  components: {
    StaffForm
  },
  data() {
    return {
      isDefault: false
    }
  },
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'staff',
        returnedPath: { name: 'staff-list' },
        isNew: this.isNew,
        deleteTooltip: this.$t(
          'pages.staff.edit.tooltips.cannot_delete_default_staff'
        ),
        deleteDisabled: this.isDefault
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.handleSubmit()
    },
    handleDelete() {
      this.$refs.form.handleDelete()
    }
  }
}
</script>
