<template>
  <th-wrapper collapsable :title="$t('pages.staff.form.headers.contact')">
    <el-form>
      <el-row :gutter="20">
        <el-col :md="12">
          <!-- Email -->
          <el-form-item prop="email" :label="$t('common.forms.labels.email')">
            <el-input
              id="email"
              v-model="form.email"
              :placeholder="$t('common.forms.labels.email')"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <!-- Phone numbers -->
      <phone-inputs
        ref="staff-contact-phones"
        v-model="form.phonenumbers"
        :phone-types="phoneTypes"
      />

      <el-row :gutter="20">
        <el-col :md="12">
          <!-- Address information -->
          <address-row
            v-model="address"
            hide-delete-button
            hide-label-type
            @update:modelValue="address = $event"
          />
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'
import set from 'just-safe-set'
import PhoneInputs from '@/components/contact-details/phones'
import AddressRow from '@/components/tillhub/addresses-input-v2/address/address-row'
import { STAFF_PHONE_TYPES } from '@/constants'

export default {
  components: { PhoneInputs, AddressRow },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    address: {
      get() {
        return get(this.form, 'addresses.0', {})
      },
      set(value) {
        set(this.form, 'addresses.0', value)
      }
    },
    phoneTypes() {
      return Object.values(STAFF_PHONE_TYPES)
    }
  }
}
</script>
