<template>
  <th-wrapper
    v-loading="loading"
    :title="$t('pages.staff.form.reservations.title')"
    collapsable
  >
    <template #actions>
      <staff-services-modal
        :model-value="form.services"
        @update:modelValue="updateEmployeeServices"
      />
    </template>

    <div v-if="form.services === null">
      {{ $t('pages.staff.form.reservations.employee_performs_all_services') }}
    </div>

    <staff-services-table
      v-else-if="!!serviceEntities"
      :service-entities="serviceEntities"
      :resources="resources"
    />

    <staff-availability
      ref="staff-availability"
      v-model="form"
      :saves-count="savesCount"
      @save="$emit('save')"
    />

    <staff-settings :employee="form" />
  </th-wrapper>
</template>

<script>
import StaffServicesTable from './staff-services-table.vue'
import StaffServicesModal from './staff-services-modal.vue'
import StaffAvailability from './staff-availability.vue'
import StaffSettings from './staff-settings.vue'
import th from '@tillhub/javascript-sdk'

export default {
  name: 'StaffReservations',
  components: {
    StaffServicesTable,
    StaffServicesModal,
    StaffAvailability,
    StaffSettings
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    resources: {
      type: Object,
      required: true
    },
    savesCount: {
      type: Number,
      required: true
    }
  },
  emits: ['update:modelValue', 'save'],
  data() {
    return {
      loading: false,
      serviceEntities: null
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  },
  async created() {
    await this.fetchServiceEntities()
  },
  methods: {
    validate() {
      return this.$refs['staff-availability'].validate()
    },
    async fetchServiceEntities() {
      const employeeId = this.$route.params.id

      if (!employeeId) {
        return
      }

      try {
        this.loading = true

        const { data } = await th.staff().getServices(employeeId)

        this.serviceEntities = data
      } catch (error) {
        this.$logException(error, {
          trackError: false
        })
      } finally {
        this.loading = false
      }
    },
    updateEmployeeServices(selectedServices) {
      this.$ampli.eventWithBaseProps('employeeServicesUpdated')

      if (selectedServices === null) {
        this.form.services = null
        this.serviceEntities = null

        return
      }

      this.form.services = selectedServices.map((service) => service.id)
      this.serviceEntities = selectedServices
    }
  }
}
</script>
